import React, { useState } from 'react'
import styled from 'styled-components'
import * as colors from '../config/theme/colors'
import media from '../config/theme/media'
import {
  Accordion as ReachAccordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@reach/accordion'

const Accordion = ({ accordionData, headerColor = colors.primary(), contentColor = colors.grey(), depth = 0 }) => {
  const [activeIndex, setActiveIndex] = useState()

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const HeaderText = styled.h3`
  font-size: 18px;
  color: black;`

  const AccordionContainer = styled(ReachAccordion)`
    width: auto; 
    color: white;
    border-top: ${depth < 2 && '1px solid #ccc'};
  `

  const AccordionItemContainer = styled(AccordionItem)`
    width: auto; 
  `

  const AccordionHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    width: auto; 
    border-bottom: 1px solid #ccc; 
    background-color: ${headerColor};
  `

  const AccordionToggle = styled(AccordionButton)`
    display: flex;  
    justify-content: center;  
    align-items: center;  
    background-color: #ececec;
    width: 44px;
    height: 44px;
    border-radius: 8px;
    margin-left: 10px;
    box-shadow: none;
  ${media.lessThan('md')`
    margin-left: 20px;  
  `}
`

  const ArrowDown = styled.div`
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 12px solid ${colors.hyperlink()};
    transition: transform 0.3s ease;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  `

  const AccordionContent = styled(AccordionPanel)`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: max-height 0.4s ease, opacity 0.3s ease, transform 0.3s ease;
    transform-origin: top;
    background-color: ${contentColor};
    color: ${depth > 1 ? 'gray' : 'black'};
    padding-left: ${depth > 1 ? depth * 10 : 0}px;
  `
  const AccordionTextContent = styled(AccordionContent)`
    color: black;
    padding: 16px;
  `

  return (
    <AccordionContainer>
      {accordionData.map((item, index) => (
        <AccordionItemContainer key={index}>
          <AccordionHeader >
            {item.link ? item.link : <HeaderText>{item.title}</HeaderText>}
            {item.content && (
              <AccordionToggle aria-label={activeIndex === index ? "Close" : "Open"} onClick={() => toggleAccordion(index)}>
                <ArrowDown isOpen={activeIndex === index} />
              </AccordionToggle>
            )}
          </AccordionHeader>
          {activeIndex === index && (
              typeof item.content === 'string' ? (
                <AccordionTextContent>
                  {item.content}
                </AccordionTextContent>
              ) : (
                <AccordionContent>
                  {item.content}
                </AccordionContent>
              )
          )}

        </AccordionItemContainer>
      ))}
    </AccordionContainer>
  )
}


export default Accordion
